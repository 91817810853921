// BAM component themes

// data-display
import { badgeTheme as Badge } from '../components/data-display/badge/badge.theme';
import { colorSwatchTheme as ColorSwatch } from '../components/data-display/color-swatch/color-swatch.theme';
import { descriptionListTheme as DescriptionList } from '../components/data-display/description-list/description-list.theme';
import { graphicCardTheme as GraphicCard } from '../components/data-display/graphic-card/graphic-card.theme';
import { imageCardTheme as ImageCard } from '../components/data-display/image-card/image-card.theme';
import { leapCardTheme as LeapCard } from '../components/data-display/leap-card/leap-card.theme';
import { leapCardContentTheme as LeapCardContent } from '../components/data-display/leap-card/leap-card-content.theme';
import { leapCardImageTheme as LeapCardImage } from '../components/data-display/leap-card/leap-card-image.theme';
import { listTheme as List } from '../components/data-display/list/list.theme';
import { miniCardTheme as MiniCard } from '../components/data-display/mini-card/mini-card.theme';
import { notificationTheme as Notification } from '../components/data-display/notification/notification.theme';
import { paginationTheme as Pagination } from '../components/data-display/pagination/pagination.theme';
import { promoCardTheme as PromoCard } from '../components/data-display/promo-card/promo-card.theme';
import { tableTheme as Table } from '../components/data-display/table/table.theme';

// disclosure
import { accordionTheme as Accordion } from '../components/disclosure/accordion/accordion.theme';
import { tabsTheme as Tabs } from '../components/disclosure/tabs/tabs.theme';

// feedback
import { alertTheme as Alert } from '../components/feedback/alert/alert.theme';
import { spinnerTheme as Spinner } from '../components/feedback/spinner/spinner.theme';

// forms
import { autocompleteOptionTheme as AutocompleteOption } from '../components/forms/autocomplete/autocomplete.theme';
import { buttonTheme as Button } from '../components/forms/button/button.theme';
import { buttonSelectTheme as ButtonSelect } from '../components/forms/button-select/button-select.theme';
import { buttonSelectButtonTheme as ButtonSelectButton } from '../components/forms/button-select/button-select-button.theme';
import { checkboxTheme as Checkbox } from '../components/forms/checkbox/checkbox.theme';
import { fileUploadTheme as FileUpload } from '../components/forms/file-upload/file-upload.theme';
import { formControlTheme as Form } from '../components/forms/form-control/form-control.theme';
import { formErrorTheme as FormError } from '../components/forms/form-control/form-error.theme';
import { formLabelTheme as FormLabel } from '../components/forms/form-control/form-label.theme';
import { imageRadioTheme as ImageRadio } from '../components/forms/image-radio/image-radio.theme';
import { inputTheme as Input } from '../components/forms/input/input.theme';
import { radioTheme as Radio } from '../components/forms/radio/radio.theme';
import { singleSelectTheme as Select } from '../components/forms/single-select/single-select.theme';
import { sliderTheme as Slider } from '../components/forms/slider/slider.theme';
import { switchTheme as Switch } from '../components/forms/switch/switch.theme';
import { textareaTheme as Textarea } from '../components/forms/textarea/textarea.theme';

// media-icons
import { featuredIconTheme as FeaturedIcon } from '../components/media-icons/featured-icon/featured-icon.theme';
import { lightboxTheme as LightBox } from '../components/media-icons/lightbox/lightbox.theme';

// navigation
import { breadcrumbTheme as Breadcrumb } from '../components/navigation/breadcrumb/breadcrumb.theme';
import { linkTheme as Link } from '../components/navigation/link/link.theme';
import { progressStepsTheme as ProgressSteps } from '../components/navigation/progress-steps/progress-steps.theme';

// other
import { closeButtonTheme as CloseButton } from '../components/other/close-button/close-button.theme';

// overlay
import { menuTheme as Menu } from '../components/overlay/menu/menu.theme';
import { modalTheme as Modal } from '../components/overlay/modal/modal.theme';
import { popoverTheme as Popover } from '../components/overlay/popover/popover.theme';
import { tooltipTheme as Tooltip } from '../components/overlay/tooltip/tooltip.theme';

// typography
import { headingTheme as Heading } from '../components/typography/heading/heading.theme';
import { pageSectionTheme as PageSection } from '../components/typography/page-section/page-section.theme';
import { subheadingTheme as Subheading } from '../components/typography/subheading/subheading.theme';
import { textTheme as Text } from '../components/typography/text/text.theme';

// App component themes
import { navItemTheme as NavItem } from '../templates/primary/components/nav-item/nav-item.theme';
import { navLinkTheme as NavLink } from '../templates/primary/components/nav-link/nav-link.theme';

import { theme as chakraDefaultTheme, extendTheme } from '@chakra-ui/react';
import { foundations } from './foundations';
import { globalStyles } from './global-styles';
import { pxToRem } from '../extensions/px-to-rem';
import { styleTokens } from './style-tokens';
import { textStyles } from './text-styles';

// Chakra defaults we want to extend
const themeExtensions = {
  components: {
    Accordion,
    Alert,
    AutocompleteOption,
    Breadcrumb,
    ButtonSelect,
    ButtonSelectButton,
    Badge,
    Button,
    Checkbox,
    CloseButton,
    ColorSwatch,
    DescriptionList,
    FeaturedIcon,
    FileUpload,
    FormError,
    Form,
    FormLabel,
    GraphicCard,
    Heading,
    ImageCard,
    ImageRadio,
    Input,
    LeapCard,
    LeapCardContent,
    LeapCardImage,
    Link,
    LightBox,
    List,
    Menu,
    MiniCard,
    Modal,
    NavItem,
    NavLink,
    Notification,
    PageSection,
    Pagination,
    Popover,
    ProgressSteps,
    PromoCard,
    Radio,
    Select,
    Slider,
    Spinner,
    Subheading,
    Switch,
    Table,
    Tabs,
    Text,
    Textarea,
    Tooltip,
  },
  pxToRem,
  styleTokens,
  textStyles,
  styles: {
    global: globalStyles,
  },
};

// Chakra defaults we want to overwrite
const theme = {
  ...chakraDefaultTheme,
  ...foundations,
};

export const bamTheme = extendTheme(themeExtensions, theme);
export default bamTheme;
